@import 'std'
@import 'base'
@import 'doors'
@import 'mediaqueries'

svg
  wf();hf();
.rope,.hook
  position: absolute;width: 16px;
  bottom:calc(100% + 8px );left: 0;right: 0;margin: 0 auto;
  z-index:0;
  pointer-events:none;
  height auto;
.hook
  width:78px;height:1180px;bottom:100%;
.ropes
  position:absolute;width:100%;z-index:0;
  pointer-events:none;
  svg
    position:absolute;
  svg:first-child
    left:32*4px;right:auto;
    transform:scale(-1,1);
  svg:last-child
    right:32*4px;left:auto;

.grecaptcha-badge
  display:none !important

html
  font-family:a,sans-serif;font-weight:800;line-height:1;upperCase();
  user-select:none;c(2);text-align:center;
  font-size:22px
  body
    p(0);m(0);overHidden();
    .kaaanet
      display:flex;align-items:center;justify-content:center;
      position:absolute;top:0;bottom:0;width:100vw;height:100vh;width:100svw;height:100svh;
    @import 'littles'
    @import 'hub/hub'
    @import 'arena'
    @import 'catcher'
    &>.over
      display:grid;place-items:center;
      z-index:8;
      .crown:not(svg)
        stdIn();s(40,12);padding:6px;
        svg{fill:colors[3];}
      &>div
        Mw(80);width:fit-content;height:fit-content;
        absoluteCenter();list()
        transitionBonceOn();
        .rope
          bottom:8px;
      .keys
        w(60)
